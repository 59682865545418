<template>
    <div>
        <!-- <ads-test></ads-test> -->
        <div class="header pb-6 pt-5 pt-lg-8 d-flex align-items-center profile-header" style="
        min-height: 150px;
        background-size: cover;
        background-position: center top;
      ">
            <b-container fluid>
                <!-- Mask -->
                <span style="background-color: #8f384d" class="mask opacity-8"></span>
                <!-- Header container -->
                <b-container fluid class="d-flex align-items-center"> </b-container>
            </b-container>
        </div>

        <vue-easy-lightbox :visible="visible" :imgs="imgs" :index="index" @hide="handleHide"></vue-easy-lightbox>

        <modal :show.sync="modals.notice" modal-classes="modal-danger" modal-content-classes="bg-gradient-success">
            <!-- <h6 slot="header" class="modal-title">Your attention is required</h6> -->

            <div class="py-3">
                <div class="text-center">
                    <i class="ni ni-bell-55 ni-3x"></i>
                    <h4 class="heading mt-4">Welcome Engineer !</h4>
                    <div style="color: black">มาเตรียมตัวสอบ ใบ กว. กับ</div>
                    <h4 style="color: white">ENGenius</h4>
                    <div style="color: black">ตัวช่วยที่ทำให้คุณได้</div>
                </div>

                <div style="color: black">
                    <ul>
                        <li>อ่านข้อสอบแบบสุ่ม</li>
                        <li>จำลองการสอบจริง</li>
                        <li>เก็บสถิติคะแนน</li>
                        <li>เพิ่มความมั่นใจในการสอบให้เต็ม 100%</li>
                    </ul>
                </div>
                <iframe v-if="!packageDisable" data-aa="1710287" src="//ad.a-ads.com/1710287?size=468x60" scrolling="no"
                    style="
            width: 468px;
            height: 60px;
            border: 0px;
            padding: 0;
            overflow: hidden;
          " allowtransparency="true"></iframe>

                <div class="text-center">พร้อมแล้วมาลุยกันเลย !!</div>
            </div>

            <template slot="footer">
                <base-button type="white" @click="modals.notice = false">เริ่มต้นใช้งาน</base-button>
                <!-- <base-button
          type="link"
          class="text-white ml-auto"
          @click="modals.notice = false"
          >Close</base-button
        > -->
            </template>
        </modal>

        <modal :show.sync="modals.classic" size="md">
            <div class="text-center">
                <!-- <h4> {{modals.new ? 'ข้อสอบใหม่' : 'ข้อสอบเก่า'}} </h4> -->
                <!-- <div class="mb-6 text-left">
              เลขข้อสีเขียว <b-button
                        style="border-radius: 50%"
                        size="sm"
                        variant="success"
                        > 
                      </b-button> = ข้อสอบที่ EnGenius สร้างขึ้นเพื่อให้ครอบคลุมเนื้อหาที่มีการอัพเดทใหม่

        </div> -->
                <img :style="'max-width: 100%;max-height: ' + windowHeight / 1.5 + 'px'"
                    src="@/assets/alert_new_exam.webp" />
            </div>
            <template slot="footer">
                <div>
                    <base-button type="primary" size="sm" @click="modals.classic = false">ปิด</base-button>
                </div>
            </template>
        </modal>

        <b-container fluid class="mt--6">
            <b-row>
                <b-col xl="12" class="order-xl-1">
                    <b-card v-if="$store.state.loading">
                        <div v-if="$store.state.subject_index == 'undefined'">
                            <base-input label="เลือกวิชา">
                                <select class="form-control" v-model="$store.state.subject_index">
                                    <option v-for="(t, index) in this.$store.state.subject_names" :key="index"
                                        :value="t.value">
                                        {{ t.text }}
                                    </option>
                                </select>
                            </base-input>
                        </div>
                        <div v-else>Loading...</div>
                    </b-card>

                    <b-card v-else id="nav-scroller" ref="content" :style="'position: relative; overflow-y: scroll; height: ' +
                        windowHeight +
                        'px'
                        ">
                        <!-- <b-card v-else id="nav-scroller" ref="content"> -->
                        <div v-if="!$store.state.username" class="mb-3">
                            <b-button variant="success" class="text-left mb-1"
                                @click="$store.commit('NEXT_PAGE', { page: 'register' })">เข้าสู่ระบบเพื่อรับข้อสอบเต็ม</b-button>
                        </div>
                        <div v-else>
                            <div v-if="!$store.state.department">
                                <b-button variant="primary" class="text-left mb-1"
                                    @click="$store.commit('NEXT_PAGE', { page: 'โปรไฟล์' })">เลือกสาขา</b-button>
                            </div>
                            <div v-if="!packageDisable && $store.state.user_time_per_day == 0">
                                <div class="text-center" v-if="
                                    !($store.state.topic_index == 10 || $store.state.topic_index == 11)
                                ">
                                    <b-row>
                                        <b-col>
                                            เวลาการใช้งานต่อวันหมดแล้ว ใช้ได้ฟรี 30 นาที/วัน
                                            รีเซ็ตทุกๆ 6 โมงเช้า
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col>
                                            กรุณาเพิ่มเวลาเพื่อเข้าถึงฟังก์ชันทดสอบ และ สอบจริง
                                        </b-col>
                                    </b-row>
                                    <br />
                                    <b-button variant="primary" class="text-left mb-1"
                                        @click="$store.commit('NEXT_PAGE', { page: 'พรีเมี่ยม' })">เพิ่มเวลา</b-button>
                                </div>
                            </div>
                        </div>

                        <h6 class="heading text-muted mb-4" id="v-step-0"
                            v-if="!(!packageDisable && $store.state.user_time_per_day == 0)">
                            {{ $store.state.subject_current }}

                        </h6>
                        <div v-for="item in be_engineer" :key="item.subject">
                            <div v-if="item.subject == $store.state.subject_current">
                                <b-card body-class="p-2" class="mb-3 bg-gradient-primary text-center"
                                    @click="nextLink(item.links[0].link)" style="color: white;">
                                    <span style="font-size: 16px;">
                                        <span style="font-size: 30;"> 📌 </span>
                                        คอร์สเรียน วิชา <span>
                                            <u>
                                                <span style="color: white;">{{
                                                    item.subject2 }}</span>
                                            </u>
                                        </span>
                                        รับส่วนลด
                                        <span style="font-size: 20px;"><b> 100 </b></span>
                                        บาท 🤩

                                        เมื่อกรอก code : <b> ENGENIUS </b>
                                    </span>
                                </b-card>
                            </div>
                        </div>
                        <v-tour name="myTour" :steps="steps"></v-tour>
                        <div class="v-step-1"></div>
                        <b-row>
                            <b-col>
                                <!-- <vue-gallery-slideshow
                  :images="$store.state.subject_cover"
                  :index="index"
                  @close="index = null"
                ></vue-gallery-slideshow> -->
                                <gallery :images="$store.state.subject_cover" :index="index" @close="index = null">
                                </gallery>
                                <div v-for="(item, number) in $store.state.items" :key="number">
                                    <!-- username -->
                                    <div v-if="$store.state.username">
                                        <div v-if="
                                            $store.state.subject_index >= 991 ||
                                            !(
                                                !packageDisable && $store.state.user_time_per_day == 0
                                            )
                                        ">
                                            <!-- {{ item.tag }} -->
                                            <b-button @click="modals.new = item.tag; modals.classic = true"
                                                style="border-radius: 50%" size="sm"
                                                :variant="item.tag ? 'success' : 'white'" :ref="'question' + number">{{
                                                    item.id + 1 }}.
                                            </b-button>
                                            <div v-if="
                                                $store.state.subject_index == 991 ||
                                                $store.state.subject_index == 992 ||
                                                $store.state.subject_index == 993 ||
                                                $store.state.subject_index == 994 ||
                                                $store.state.subject_index == 995
                                            ">
                                                <!-- <expandable-image
                          :src="
                            $store.state.baseAPI +
                            '/subjects/solve/' +
                            $store.state.subject_index +
                            '/' +
                            (item.id + 1) +
                            '/' +
                            token
                          "
                        /> -->
                                                <div v-if="number < 2 || addOnSelected.includes(999)">
                                                    <img :style="'max-width: 100%;max-height: ' +
                                                        windowHeight / 1.5 +
                                                        'px'
                                                        " :src="$store.state.baseAPI +
                                                            '/subjects/solve/' +
                                                            $store.state.subject_index +
                                                            '/' +
                                                            (item.id + 1) +
                                                            '/' +
                                                            token
                                                            " @click="onClick(number)" />
                                                </div>
                                                <div v-else>
                                                    <b-button size="sm" variant="primary" class="text-left mb-1" @click="
                                                        $store.commit('NEXT_PAGE', {
                                                            page: 'พรีเมี่ยม',
                                                        })
                                                        ">เฉลยละเอียดเพิ่มเติม</b-button>
                                                </div>
                                            </div>
                                            <b v-html="item.question"></b>
                                            <!-- <b v-html="item.question.includes('<img') ? filterExpandImage(item.question, 0) : item.question"></b>
                      <expandable-image close-on-background-click v-if="item.question.includes('<img')" :src="filterExpandImage(item.question, 1)"/> -->
                                            <div v-for="(c, done) in item.choice" :key="done"
                                                v-show="$store.state.solve1 && done + 1 == item.ans">
                                                <!-- {{ done + 1 }}. -->
                                                <label style="background-color: powderblue">
                                                    {{ done + 1 + ". " }}
                                                </label>
                                                <label style="background-color: powderblue" v-html="c">
                                                </label>
                                            </div>
                                            <ol>
                                                <div v-for="(c, done) in item.choice" :key="done"
                                                    v-show="!$store.state.solve1">
                                                    <!-- label -->
                                                    <div v-if="$store.state.solve">
                                                        <div v-if="done + 1 == item.ans">
                                                            <label style="background-color: powderblue">
                                                                <!-- ✅  -->
                                                                {{ done + 1 + ". " }}
                                                            </label>
                                                            <label style="background-color: powderblue"
                                                                v-html="c"></label>
                                                        </div>
                                                        <div v-if="!$store.state.solve1">
                                                            <div v-if="done + 1 != item.ans">
                                                                <label> {{ done + 1 + ". " }}</label>
                                                                <label v-html="c"></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- buttom -->
                                                    <div v-else>
                                                        <div v-if="item.done == -1">
                                                            <b-button variant="outline-primary" class="text-left mb-1"
                                                                v-on:click="
                                                                    $store.commit('SHOW_RESULT', {
                                                                        number: number,
                                                                        done: done,
                                                                    })
                                                                    " v-html="c"></b-button>
                                                        </div>
                                                        <div v-else>
                                                            <b-button variant="success" class="text-left mb-1"
                                                                v-html="c" v-if="done + 1 == item.ans"></b-button>
                                                            <b-button variant="danger" class="text-left mb-1" v-html="c"
                                                                v-else-if="
                                                                    item.done != item.ans && done + 1 == item.done
                                                                "></b-button>
                                                            <b-button variant="outline-primary" class="text-left mb-1"
                                                                v-html="c" v-else></b-button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ol>
                                            <!-- {{item}} -->
                                            <div v-if="
                                                $store.state.subject_index == 2 ||
                                                $store.state.subject_index == 4 ||
                                                $store.state.subject_index == 1 ||
                                                $store.state.subject_index == 3 ||
                                                $store.state.subject_index == 7 ||
                                                $store.state.subject_index == 8 ||
                                                $store.state.subject_index == 9 ||
                                                $store.state.subject_index == 107 ||
                                                $store.state.subject_index == 89 ||
                                                $store.state.subject_index == 45 ||
                                                $store.state.subject_index == 46 ||
                                                $store.state.subject_index == 43 ||
                                                $store.state.subject_index == 37 ||
                                                $store.state.subject_index == 19 ||
                                                $store.state.subject_index == 996 ||
                                                $store.state.subject_index == 997 ||
                                                $store.state.subject_index == 998 ||
                                                $store.state.subject_index == 999
                                            ">
                                                <b-card v-show="$store.state.checked_solve &&
                                                    (item.done != -1 || $store.state.solve)
                                                    " title="เฉลยละเอียด" class="mb-9">
                                                    <div v-if="
                                                        number < 5 ||
                                                        addOnSelected.includes(parseInt(subject_index))
                                                    ">
                                                        <img :style="'max-width: 100%;max-height: ' +
                                                            windowHeight / 1.5 +
                                                            'px'
                                                            " :src="$store.state.baseAPI +
                                                                '/subjects/solve/' +
                                                                $store.state.subject_index +
                                                                '/' +
                                                                (item.id + 1) +
                                                                '/' +
                                                                token
                                                                " @click="() => showImg(item.id + 1)" />
                                                    </div>
                                                    <div v-else>
                                                        <b-button size="sm" variant="primary" class="text-left mb-1"
                                                            @click="
                                                                $store.commit('NEXT_PAGE', {
                                                                    page: 'พรีเมี่ยม',
                                                                })
                                                                ">เฉลยละเอียดเพิ่มเติม</b-button>
                                                    </div>
                                                </b-card>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- !username -->
                                    <div v-else>
                                        <div v-if="number < $store.state.number_exam">
                                            <span v-if="number < 5"><b>{{ number + 1 }}. </b>
                                            </span>
                                            <span v-else><b>{{ $store.state.items.length - (10 - number) + 1 }}.
                                                </b>
                                            </span>
                                            <b v-html="item.question"></b>
                                            <!-- <b v-html="item.question.includes('<img') ? filterExpandImage(item.question, 0) : item.question"></b>
                      <expandable-image close-on-background-click v-if="item.question.includes('<img')" :src="filterExpandImage(item.question, 1)"/> -->
                                            <div v-for="(c, done) in item.choice" :key="done"
                                                v-show="$store.state.solve1 && done + 1 == item.ans">
                                                {{ done + 1 }}.
                                                <label style="background-color: powderblue" v-html="c">
                                                </label>
                                            </div>
                                            <ol>
                                                <li v-for="(c, done) in item.choice" :key="done"
                                                    v-show="!$store.state.solve1">
                                                    <!-- label -->
                                                    <div v-if="$store.state.solve">
                                                        <div v-if="done + 1 == item.ans">
                                                            <label style="background-color: powderblue"
                                                                v-html="c"></label>
                                                        </div>
                                                        <div v-if="!$store.state.solve1">
                                                            <div v-if="done + 1 != item.ans">
                                                                <label v-html="c"></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- buttom -->
                                                    <div v-else>
                                                        <div v-if="item.done == -1">
                                                            <b-button variant="outline-primary" class="text-left mb-1"
                                                                v-on:click="
                                                                    $store.commit('SHOW_RESULT', {
                                                                        number: number,
                                                                        done: done,
                                                                    })
                                                                    " v-html="c"></b-button>
                                                        </div>
                                                        <div v-else>
                                                            <b-button variant="success" class="text-left mb-1"
                                                                v-html="c" v-if="done + 1 == item.ans"></b-button>
                                                            <b-button variant="danger" class="text-left mb-1" v-html="c"
                                                                v-else-if="
                                                                    item.done != item.ans && done + 1 == item.done
                                                                "></b-button>
                                                            <b-button variant="outline-primary" class="text-left mb-1"
                                                                v-html="c" v-else></b-button>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ol>

                                            <div v-if="
                                                (number == $store.state.number_exam / 2 - 1 &&
                                                    !$store.state.randomQ) ||
                                                (number == $store.state.number_exam - 1 &&
                                                    $store.state.randomQ)
                                            ">
                                                <br />.<br />.<br />.<br />.<br />.<br />.<br />.<br />.<br />.<br />.<br />.<br />
                                            </div>
                                            <hr />
                                        </div>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                        <div v-if="!$store.state.username">
                            <b-button variant="success" class="text-left mb-1"
                                @click="$store.commit('NEXT_PAGE', { page: 'register' })">เข้าสู่ระบบเพื่อรับข้อสอบเต็ม</b-button>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>

        <fab class="mb--3" id="v-step-2" :toggle-when-away="$store.state.toggleWhenAway"
            :fixed-tooltip="$store.state.fixedTooltip" :position="$store.state.position"
            :bg-color="$store.state.bgColor" :actions="$store.state.fabActions" @lightbulb="$store.commit('LIGHTBULB')"
            @compress="$store.commit('COMPRESS')" @shuffle="$store.commit('SHUFFLE')"
            @autorenew="$store.commit('AUTO_RENEW')"></fab>
    </div>
</template>

<script>
import fab from "vue-fab";
import moment from "moment";
// import { BaseSwitch } from "@/components";
// import VueGallerySlideshow from "vue-gallery-slideshow";
import VueGallery from "vue-gallery";

export default {
    components: {
        fab,
        // BaseSwitch,
        // VueGallerySlideshow,
        gallery: VueGallery,
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    watch: {
        "$store.state.goToTestNumber": function (val) {
            if (val) {
                var number = localStorage.test_number;
                this.$nextTick(() => {
                    const divRef = this.$refs["question" + number][0];
                    divRef.focus();
                    this.$store.state.goToTestNumber = false;
                });
            }
        },
        "$store.state.nextIndex": function (val) {
            this.$nextTick(() => {
                if (val != 0) {
                    const divRef = this.$refs["question" + val][0];
                    divRef.focus();
                    this.$store.state.nextIndex = 0;
                }
            });
        },
        "$store.state.loading": function (val) {
            if (!val) {
                var number = localStorage.test_number;
                this.$nextTick(() => {
                    const divRef = this.$refs["question0"][0];
                    divRef.focus();
                    if (this.$store.state.new_exam_list.includes(this.$store.state.subject_index)) {
                        const localSubject = localStorage.getItem("subject" + this.$store.state.subject_index);
                        if (localSubject == null) {
                            this.modals.classic = true;
                            localStorage.setItem("subject" + this.$store.state.subject_index, "true");
                        }
                    }
                });
            }
        },
    },
    mounted: function () {
        // this.reload();
        if (this.$store.state.first) {
            this.showModal();
            this.$store.state.first = false;
        }
        this.addOnSelected = this.userPackage.subject.slice();
        if (this.addOnSelected.includes(999)) {
            this.addOnSelected.push(996);
            this.addOnSelected.push(997);
            this.addOnSelected.push(998);
        }
        // console.log(this.addOnSelected);
        // this.$tours["myTour"].start();
        // console.log(this.subject_cover);
        // this.subject_cover = [];
        // for (let i = 0; i < this.$store.state.items; i++) {
        //   const item = this.$store.state.items[i];
        //   var url =
        //     this.$store.state.baseAPI +
        //     "/subjects/solve/" +
        //     this.$store.state.subject_index +
        //     "/" +
        //     (item.id + 1) +
        //     "/" +
        //     this.token;
        //   console.log(url);
        //   this.subject_cover.push(url);
        // }
        // console.log(this.subject_cover);
    },
    data() {
        return {
            be_engineer: [
                {
                    subject: "Heat Transfer",
                    subject2: "Heat Transfer",
                    links: [
                        { name: "(SU) final", link: "https://www.odm-engineer.com/course/Heat-Transfer-(SU)-final-3289" },
                        { name: "(SUT) final", link: "https://www.odm-engineer.com/course/Heat-Transfer-(SUT)-final-3335" },
                        { name: "(SUT) midterm", link: "https://www.odm-engineer.com/course/Heat-Transfer-(SUT)-midterm-3336" },
                    ]
                },
                {
                    subject: "Machine Design",
                    subject2: "Machine Design",
                    links: [
                        { name: "มหาลัยทั่วไป#1", link: "https://www.odm-engineer.com/course/Machine-Design-%E0%B8%A1%E0%B8%AB%E0%B8%B2%E0%B8%A5%E0%B8%B1%E0%B8%A2%E0%B8%97%E0%B8%B1%E0%B9%88%E0%B8%A7%E0%B9%84%E0%B8%9B-1-3470" },
                        { name: "(NU) final", link: "https://www.odm-engineer.com/course/Machine-Design-(NU)-final-3031" },
                        { name: "(PSU) final", link: "https://www.odm-engineer.com/course/Machine-Design-(PSU)-final-3136" },
                        { name: "Machine Design 2 (SU) final", link: "https://www.odm-engineer.com/course/Machine-Design-2-(SU)-final-3022" },
                    ]
                },
                {
                    subject: "Mechanics of Machinery",
                    subject2: "Mechanics และ Machinery",
                    links: [
                        { name: "Machinery (KKU) final", link: "https://www.odm-engineer.com/course/Machinery-(KKU)-final-3011" },
                        { name: "Mechanics (CATC) final", link: "https://www.odm-engineer.com/course/Mechanics-(CATC)-final-3376" },
                        { name: "Mechanics (SU) final", link: "https://www.odm-engineer.com/course/Mechanics-(SU)-final-3221" },
                        { name: "Machinery (RMUTTO) final", link: "https://www.odm-engineer.com/course/Machinery-(RMUTTO)-final-3418" },
                    ]
                },
                {
                    subject: "Engineering Mechanic/Static/Dynamic",
                    subject2: "Static",
                    links: [
                        { name: "กว", link: "https://www.odm-engineer.com/c/mechanics-coe" },
                        { name: "(KKU) final", link: "https://www.odm-engineer.com/course/Statics-(KKU)-final-3004" },
                        { name: "(PSU) module2", link: "https://www.odm-engineer.com/course/Statics-(PSU)-module2-3170" },
                        { name: "(RMUTI) final", link: "https://www.odm-engineer.com/course/Statics-(RMUTI)-final-3046" },
                        { name: "(RMUTR KKW) final", link: "https://www.odm-engineer.com/course/Statics-(RMUTR-KKW)-final-3222" },
                        { name: "(RTNA) midterm", link: "https://www.odm-engineer.com/course/Statics-(RTNA)-midterm-3493" },
                        { name: "(RU) midterm", link: "https://www.odm-engineer.com/course/Statics-(RU)-midterm-3399" },
                        { name: "(SUT) midterm", link: "https://www.odm-engineer.com/course/Statics-(SUT)-midterm-3264" },
                        { name: "(SWU) final", link: "https://www.odm-engineer.com/course/Statics-(SWU)-final-3326" },
                    ]
                }

            ],
            visible: false,
            index_solve: 0, // default: 0
            imgs: [],
            addOnSelected: [],
            windowHeight: 0,
            modals: {
                notice: false,
                classic: false
            },
            index: -1,
            //   number_exam: 10,
            arr_subject: [4, 2, 1],
            steps: [{
                target: "#v-step-0", // We're using document.querySelector() under the hood
                header: {
                    title: "เริ่มเรียนรู้ไปกับเรา",
                },
                content: `<strong>ทัวร์</strong>!`,
            },
            {
                target: ".v-step-1",
                content: "ทดลองตอบข้อแรกได้เลย <br /> <span> สีเขียว = ถูก </span> <br /> <span> สีแดง = ผิด </span>",
            },
            {
                target: "#v-step-2",
                content: "4 ฟังก์ชัน ช่วยให้อ่านตามสไตล์ที่ใช่",
                params: {
                    placement: "top", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
                },
            },
            ],
        };
    },
    methods: {
        nextLink(link) {
            // window.location.href = link;
            var payload = {
                username: this.$store.state.username,
                param: link,
                code: "be-engineer"
            };
            this.$store.commit("POST_CLICK", payload);
            window.open(link, '_blank')
        },
        showImg(index) {
            // console.log(index);
            this.imgs = [
                this.$store.state.baseAPI +
                "/subjects/solve/" +
                this.$store.state.subject_index +
                "/" +
                index +
                "/" +
                this.token,
            ];
            this.index_solve = 0;
            this.visible = true;
        },
        handleHide() {
            this.visible = false;
        },
        handleResize() {
            this.windowHeight = window.innerHeight - 145;
        },
        showModal() {
            this.modals.notice = true;
        },
        onClick(i) {
            this.index = i;
        },
        // filterExpandImage (question, type) {
        //   let itemSplit = question.split('src="')
        //   itemSplit[1] = itemSplit[1].substring(0, itemSplit[1].indexOf('"'))
        //   return itemSplit[type]
        // }
    },
    computed: {
        token() {
            return localStorage.token;
        },
        userPackage() {
            return this.$store.state.userPackage;
        },
        subject_index() {
            return this.$store.state.subject_index;
        },
        user_exp() {
            return moment(this.userPackage.exp).utc().format("DD/MM/YYYY HH:mm:ss");
        },
        date_remain() {
            return moment(this.userPackage.exp).diff(moment(), "days");
        },
        packageDisable() {
            return this.userPackage.exp ?
                moment(this.userPackage.exp).isAfter(moment()) :
                false;
        },
    },
};
</script>

<style>

img {
    max-width: 430px;
    height: auto;
}

</style>
